<template>
	<!-- <el-select
		v-model="userId"
		size="small"
		filterable
		remote
		clearable
		default-first-option
		:placeholder="$t('i18nn_1af050f1d11dee5b')"
		:remote-method="remoteMethod"
		@change="changeData"
		:loading="loading"
	>
		<el-option v-for="(item, index) in cusList" :key="index" :label="item.companyName" :value="item.userId"></el-option>
	</el-select> -->
	<el-select filterable @change="changeData" :loading="loading_sel" :clearable="clearable" multiple
		:disabled="disabled" v-model="selPullYiCangWhData" :placeholder="$t('2ad108ab2c560530')" :size="size"
		:style="{width: width+'px'}">
		<el-option v-for="(item,index) in pullYiCangWhData" :key="item.warehouse_id" :label="item.warehouse_code"
			:value="item.warehouse_id">
			<div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.warehouse_code }}</span>
				<span class="sel_option_code" style="">{{ item.warehouse_desc }}</span>
			</div>
		</el-option>
	</el-select>
</template>
<script>
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			openTime:{},
			userId: {
				default: function() {
					return ''
				},
				type: String
			},
			width: {
				default: function() {
					return 220
				},
				type: Number
			},
			size: {
				default: function() {
					return ''
				},
				type: String
			},
			clearable: {
				default: function() {
					return true
				},
				type: Boolean
			},
			disabled: {
				default: function() {
					return false
				},
				type: Boolean
			},
			// di
		},
		components: {},
		data() {
			return {
				// dialogFile: false,
				// loadingUpload: false,

				loading_sel: false,
				pullYiCangWhData: [],
				selPullYiCangWhData: [],
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// saveErr: false,

				// listUrl: [],

				// tableData: []
			};
		},
		watch: {
		  openTime: function(newVal, oldVal) {
		    console.log('openTime');
		    // this.dialogFile = true;
		    // this.initData();
				this.pullYiCangWhData = [];
				if(this.userId){
					this.getPullYiCangWhQuery();
				}
		  }
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			this.pullYiCangWhData = [];
			if(this.userId){
				 this.getPullYiCangWhQuery();
			} else {
				this.$message.warning(this.$t('i18nn_5184f7a4033b4969'));
			}
			
		},
		methods: {
			init(val) {
				if (val) {
					this.selPullYiCangWhData = val;
				} else {
					this.selPullYiCangWhData = [];
				}
			},
			changeData(data) {
				console.log("changeData", data);
				let emit_data = [];
				if (data && data.length > 0) {
					data.forEach(item => {
						let item_data = this.pullYiCangWhData.find(item2 => {
							return item2.warehouse_id == item;
						});
						emit_data.push(item_data);
					});
				}
				this.$emit('changeData', emit_data)
			},
			clearData() {
				this.selPullYiCangWhData = [];
			},
			//客户模糊搜索
			// remoteMethod(query) {
			// 	if (query !== '') {
			// 		this.getCusListData(query);
			// 	} else {
			// 		this.cusList = [];
			// 	}
			// },
			//易仓拉取查询-仓库
			getPullYiCangWhQuery() {
				// let _this = this;
				this.loading_sel = true;
				this.$http
					.put(this.$urlConfig.WhThirdYiCangWhQuery+"/"+this.userId+"/warehouse", {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('易仓拉取查询-仓库');
						console.log(data);
						
						this.loading_sel = false;
						this.pullYiCangWhData = data.rows;

					})
					.catch(error => {
						console.log(error);
						// console.log('分页，请求失败');
						this.loading_sel = false;
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
