<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				SKU拆分
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">易仓API配置</span>
						<!-- <el-tooltip effect="dark" :content="'导出excel'" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" @click="openNew()" size="small" icon="el-icon-plus">{{$t('i18nn_a6abf1dd9e065e27')}}</el-button>
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<!-- <div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>{{ item.label }}</span>
					</el-menu-item>
				</el-menu>
			</div> -->
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_a6abf1dd9e065e27')}}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">
					<li class="">
						<span>{{ $t('hytxs0000060') }}</span>
						<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
					</li>
				</ul>
				<!-- <ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo2"></whNoSelect>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
						</el-input>
					</li>

					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					
				</ul> -->
			</div>
		</div>

		<!-- <div class="wh-filter-con">
			<div class="">
			  <span>{{$t('Storage.packing_list.status')}}</span>
				<el-radio-group v-model="filterData.status" size="medium" @change="serPageData()">
				  <el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">
						{{item.label}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo2"></whNoSelect>
			</div>
			
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con">
			<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_52687a58ca0645c3')}}</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%"
				size="small" :max-height="$store.state.tableMaxHeight3">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				
				<el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip></el-table-column>
				<el-table-column prop="userName" :label="$t('i18nn_0e3b6b0b53dd94e8')"></el-table-column>
				<el-table-column prop="userPass" :label="$t('i18nn_8af36bb95bcac474')"></el-table-column>
				<el-table-column prop="ebUrl" :label="'EB Url'"></el-table-column>
				<el-table-column prop="wmsUrl" :label="'WMS Url'"></el-table-column>
				<el-table-column prop="confMatk" :label="$t('i18nn_b306361a5aeda4e8')"></el-table-column>
				
				<el-table-column prop="warehouseList" :label="$t('c944a6686d996ab3')" min-width="200">
					<template slot-scope="scope">
					
						<div>
							<ul>
								<li v-for="(item,index) in scope.row.warehouseList" :key="index">
									<el-tag>
										{{item.warehouse_code}},{{item.warehouse_desc}}
									</el-tag>
								</li>
							</ul>
						</div>
					
					</template>
				</el-table-column>
				
				<el-table-column prop="isSyncLabelName" :label="$t('i18nn_62519f438e8f64b5')"></el-table-column>
				<el-table-column prop="isAutoSyncName" :label="$t('i18nn_296b2c6a46764af7')"></el-table-column>
				<el-table-column prop="syncMethod" :label="$t('i18nn_d165fe5a9a65fb8c')"></el-table-column>
				
				<el-table-column prop="serviceToken" :label="'服务商Token'"></el-table-column>
				<el-table-column prop="serviceCode" :label="$t('i18nn_27aaf0520109d72e')"></el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="220px" fixed="right">
					<template slot-scope="scope">

						<div>
							<div >
								<el-button @click="openWhUpdate($event, scope.row, scope.$index)" type="primary" size="mini"
									icon="el-icon-refresh">{{$t('i18nn_44b27e0a8476a256')}}</el-button>
									
							</div>
							<div style="margin-top: 10px;">
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
										icon="el-icon-edit">{{ $t('FormMsg.Edit') }}</el-button>
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>

							</div>
						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 易仓API配置 -->
		<el-dialog :close-on-click-modal="false" top="10px" :title="'易仓API配置'" append-to-body
			:visible.sync="dialogFormVisible" custom-class="myFullDialog4">

			<el-form ref="form" :rules="formRules" :size="'small'" :model="form" label-width="150px"
				style="width: 1000px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">

				<!-- <el-form-item label="仓库：" required>
					<whNoSelect ref="whNoSelect" @changeData="changWhNo"></whNoSelect>
				</el-form-item> -->

				<el-card v-if="0==dialogFormStatus || 1==dialogFormStatus">
					<!-- <div slot="header"><span>要分拆的SKU</span></div> -->
					<div>
						<!-- "id":"数据ID",
						    "confMatk":$t('i18nn_b306361a5aeda4e8'),
						    "ebUrl":"",
						    "wmsUrl":"",
						    "userName":"",
						    "userPass":"",
						    "warehouseList":[
						    ] -->
				
						
						<el-form-item label="易仓用户名：" required>
							<el-input v-model="form.userName"></el-input>
						</el-form-item>
						<el-form-item label="易仓密码：" required>
							<!-- <el-input type="password" v-model="form.userPass" show-password></el-input> -->
							<el-input v-model="form.userPass"></el-input>
						</el-form-item>
						<el-form-item label="账号备注：" required>
							<el-input type="textarea" v-model="form.confMatk" :maxlength="1000" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="EB Url：" required>
							<el-input v-model="form.ebUrl"></el-input>
						</el-form-item>
						<el-form-item label="WMS Url：" required>
							<el-input v-model="form.wmsUrl"></el-input>
						</el-form-item>
						<el-form-item label="服务商Token：">
							<el-input v-model="form.serviceToken"></el-input>
						</el-form-item>
						<el-form-item label="服务商编码：">
							<el-input v-model="form.serviceCode"></el-input>
						</el-form-item>
						<el-form-item label="是否回传单号：">
							<!-- <el-input v-model="form.isSyncLabel"></el-input> -->
							<el-switch
							  v-model="form.isSyncLabel"
							  :active-text="$t('i18nn_02ccd2cf723f9272')"
							  :inactive-text="$t('i18nn_e76d885ae1c74d4f')"
								active-value="1"
								inactive-value="0">
							</el-switch>
						</el-form-item>
						<el-form-item label="是否自动同步：">
							<!-- <el-input v-model="form.isAutoSync"></el-input> -->
							<el-switch
							  v-model="form.isAutoSync"
							  :active-text="$t('i18nn_02ccd2cf723f9272')"
							  :inactive-text="$t('i18nn_e76d885ae1c74d4f')"
								active-value="1"
								inactive-value="0">
							</el-switch>
						</el-form-item>
						<el-form-item label="同步方法：">
							<el-input v-model="form.syncMethod"></el-input>
						</el-form-item>
						<!-- isSyncLabel  isAutoSync syncMethod -->
						
					</div>
				</el-card>
				<el-card style="margin-top: 10px;">
					<!-- <div slot="header"><span>要分拆的SKU</span></div> -->
					<div>
						<el-form-item label="仓库：">
							<!-- <el-checkbox-group v-model="form.warehouseList">
								<el-checkbox v-for="(item,index) in pullYiCangWhData" :label="item.id" :key="item.id">
									{{item.name}}
								</el-checkbox>
							</el-checkbox-group> -->
							<whYiCangWhSelect ref="whYiCangWhSelect" @changeData="changYiCangWh" :openTime="openTimeYiCangWh" :userId="filterData.userId" size="medium" :width="500"></whYiCangWhSelect>
						</el-form-item>
					</div>
				</el-card>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog>

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->
		<!--选择sku-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
			<div style=""><WSkuInfo :isSel="true" :status="'1'" @selectRow="selWhGoodsData"></WSkuInfo></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSelVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!-- excel 导出排序 -->
		<!-- <whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhMyGoodsSkuPageList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom> -->
	</div>
</template>
<script>
	import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	// import JQ from 'jquery';
	// import CommodityCateLinkage from '@/components/Common/CommodityCateLinkage.vue';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	// import barcode from '@/components/Common/barcode.vue';
	// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	// import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	// import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
	// import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import whYiCangWhSelect from '@/components/WarehouseCenter2/components/whYiCangWhSelect.vue';
	
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		components: {
			cusSelFuzzy,
			whYiCangWhSelect
			// CommodityCateLinkage,
			// barcode,
			// SelAgentUser,
			// WSkuInfo,
			// whNoSelect
			// whExcelCustom
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false;
		// 		},
		// 		type: Boolean
		// 	},
		// 	status: {
		// 		default: function() {
		// 			return '';
		// 		},
		// 		type: String
		// 	}
		// },
		data() {
			return {

				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				// loading_sel: false,
				// pullYiCangWhData: [],
				openTimeYiCangWh:"",

				loading: false,
				form: {
					"id": null, //"数据ID",
					"confMatk": null, //this.$t('i18nn_b306361a5aeda4e8'),
					"ebUrl": null, //"",
					"wmsUrl": null, //"",
					"userName": null, //"",
					"userPass": null, //"",
					"serviceToken":null,
					"serviceCode":null,
					
					"isSyncLabel": '1', //"",  
					"isAutoSync": '1', //"", 
					"syncMethod": null, //"",
					
					"warehouseList": [

					]
				},
				formRules: {
					// whNo: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// goodsSku: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// quantity: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }]
				},
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_no: [],
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type:[],
					wh_size_unit: [],
					// statusList: [{
					// 		value: '',
					// 		label: this.$t('16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '0',
					// 		label: this.$t('915541755261249f')
					// 	},
					// 	{
					// 		value: '10',
					// 		label: this.$t('7e14d2cd3996dcd2')
					// 	},
					// 	{
					// 		value: '20',
					// 		label: this.$t('i18nn_0ae3aed4e3b5423d')
					// 	},
					// 	{
					// 		value: '60',
					// 		label: this.$t('hytxs0000027')
					// 	},
					// 	{
					// 		value: '99',
					// 		label: this.$t('4b3340f7872b411f')
					// 	}
					// ]
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					
					userId:"",
					// agentUser: '',
					// // "accountPeriod":"",
					// // "billNo":""
					// status: '',
					// // putWhNo: '',
					// goodsSku: '',
					// // hashCode: '',
					// goodsName: '',
					// goodsNameEn: '',
					// keyword: '',
					// whNo: '',
					// declareNameCh: '',
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getPullYiCangWhQuery();
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			changYiCangWh(data) {
				console.log('changYiCangWh', data);
				// this.form.warehouseList = data.map((item)=>{
				// 	return item.warehouse_id;
				// });
				this.form.warehouseList = data;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			// changeAgentUser(val) {
			// 	this.filterData.agentUser = val;
			// 	this.initData();
			// },
			// changWhNo2(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },
			changCus(data) {
				console.log('changCus', data);
				this.filterData.userId = data.userId;
				this.initData();
			},
			// exportExcelAction() {
			// 	let columns = [
			// 		{
			// 			title: 'SKU',
			// 			key: 'goodsSku'
			// 		},
			// 		{
			// 			title: 'SKU CODE',
			// 			key: 'goodsSkuImg',
			// 			types: 'image'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.Chinese_title'),
			// 			key: 'goodsName'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.English_title'),
			// 			key: 'goodsNameEn'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.unit'),
			// 			key: 'sizeUnitName'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.length'),
			// 			key: 'goodsLength'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.width'),
			// 			key: 'goodsWidth'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.height'),
			// 			key: 'goodsHeight'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.weight'),
			// 			key: 'goodsWeight'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.price'),
			// 			key: 'declarePrice'
			// 		}
			// 	];
			// 	// let Data = this.tableDataCatch;
			// 	// return columns;
			// 	let Data = this.tableData;

			// 	this.expExcelData = Data;
			// 	this.excelHead = columns;
			// 	this.excelName = 'wh_sku';
			// 	this.excelOption = {};
			// 	this.excelOpenTime = new Date().getTime();
			// 	// excelUtilsNew.exportExcel(columns, Data, 'wh_sku');
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			openDioalog(formParm,type) {
				// console.log(formParm);
				this.dialogFormVisible = true;
				// this.openTimeYiCangWh = new Date().getTime();
				let form = Object.assign({}, formParm);
				console.log('form', form);
				// // 重置
				this.resetForm('form');

				if (0 == type) {
					//新增

					// this.form.weightUnit = '1';
					// this.form.volumeUnit = '1';
					// this.form.whFeeType = '1';

					this.dialogFormStatus = type;
					// form.subUserId = null;
					// form.userSubUserId = null;
					// form.state = true;
					//浅拷贝、对象属性的合并
					this.form = form;
					this.form.warehouseList = [];

					this.$nextTick(() => {
						this.$refs.whYiCangWhSelect.init([]);
					});
				} else {
					//修改
					this.dialogFormStatus = type;
					// form.state = form.state === '0' ? true : false;
					// this.form.goodsSubjectId = this.CateValue[0];
					// this.CateValue = [form.goodsSubjectId];
					//浅拷贝、对象属性的合并
					this.form = form;
					// this.getDetList(this.form.id);
					this.$nextTick(() => {
						let wh_ids = [];
						if(this.form.warehouseList && this.form.warehouseList.length>0){
							wh_ids = this.form.warehouseList.map(item=>{
								return item.warehouse_id;
							});
						}
						this.$refs.whYiCangWhSelect.init(wh_ids);
					});
				}
				// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
				//       type: 'warning',
				//       //confirmButtonText: this.$t('204ffab8a6e01870'),
				//     });
			},
			openNew() {
				if(!this.filterData.userId){
					this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
					return;
				}
				// event.stopPropagation();
				this.openTimeYiCangWh = new Date().getTime();
				this.openDioalog(null,0);
			},
			//打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				if(!this.filterData.userId){
					this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
					return;
				}
				this.openTimeYiCangWh = new Date().getTime();
				this.openDioalog(row,1);
			},
			//打开仓库更新
			openWhUpdate(event, row, index) {
				event.stopPropagation();
				if(!this.filterData.userId){
					this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
					return;
				}
				this.openTimeYiCangWh = new Date().getTime();
				this.openDioalog(row,2);
			},
			//删除
			delAction(event, row) {
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('204ffab8a6e01870'),
						// cancelButtonText: this.$t('4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);

				this.postData(this.$urlConfig.WhThirdYiCangConfDel + '/' + row.id, {}, 'delete');
			},

			//取消拆分
			// cancelAction(event, row) {
			// 	this.$confirm('确定取消拆分吗？', this.$t('tips.tipsTitle'), {
			// 		// confirmButtonText: this.$t('204ffab8a6e01870'),
			// 		// cancelButtonText: this.$t('4b3340f7872b411f'),
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhSkuSplitCancel + '/' + row.id, {}, 'put');
			// 		})
			// 		.catch(() => {});
			// },
			//提交
			// submitlAction(event, row) {
			// 	this.$confirm('确定提交吗？', this.$t('tips.tipsTitle'), {
			// 		// confirmButtonText: this.$t('204ffab8a6e01870'),
			// 		// cancelButtonText: this.$t('4b3340f7872b411f'),
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhSkuSplitSubmit + '/' + row.id, {}, 'put');
			// 		})
			// 		.catch(() => {});
			// },

			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						//浅拷贝、对象属性的合并
						if (0 == this.dialogFormStatus) {//新增
							formData.id = null;
							// formData.userId = this.UserInfo.id;

							this.postData(this.$urlConfig.WhThirdYiCangConfAdd+"/"+this.filterData.userId, formData);
						} else if(1==this.dialogFormStatus) {//编辑
							this.postData(this.$urlConfig.WhThirdYiCangConfAdd+"/"+this.filterData.userId, formData);
						} else {//更新仓库
							let formDataUpdateWh = {
								id: formData.id,
								warehouseList: formData.warehouseList
							};
							// formData.userId = this.UserInfo.id;
							this.postData(this.$urlConfig.WhThirdYiCangConfUpdate, formDataUpdateWh);
						}
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
				// this.CateValue = [];
				// if (this.$refs.hyUpLoadImg1) {
				// 	this.$refs.hyUpLoadImg1.initUrl('');
				// }
			},

			//提交信息
			postData(url, formData, type) {
				// let _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						this.initData();
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						//   type: 'success',
						//   //confirmButtonText: this.$t('204ffab8a6e01870'),
						// });
						// this.$message.success(this.$t('tips.submitSuccess'));
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 	type: 'success'
							// });
							this.$message.success(this.$t('tips.submitSuccess'));
						}
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
			},
			// clearFile() {
			// 	this.fileExcel = null;
			// 	this.excelFileName = '';
			// 	// this.excelFileName = "";
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.file.value = '';
			// 		} catch (e) {
			// 			console.log(e);
			// 			//TODO handle the exception
			// 		}
			// 	});
			// },

			// 新建-表格-拆分
			// addSplit() {
			// 	this.form.recordLists.push({
			// 		goodsSku: '', //"分拆后的SKU",
			// 		breakQ: 1 //"预计分拆件数",
			// 		// "id":"数据ID"
			// 	});
			// },
			// //删除-表格-拆分
			// delSplit(event, row, index) {
			// 	this.form.recordLists.splice(index, 1);
			// },
			//打开选择数据 要分拆
			// openMainSelData() {
			// 	this.dialogSelVisible = true;
			// 	this.dialogSelType = 1;
			// },
			//打开选择数据  分拆后
			// openSelData(event, row, index) {
			// 	event.stopPropagation();

			// 	this.dialogSelVisible = true;
			// 	this.dialogSelType = 2;
			// 	this.openRowData = row;
			// 	this.openRowIndex = index;
			// 	// this.$nextTick(() => {
			// 	//   this.$refs.hyUpLoadImg1.openFile();
			// 	// });
			// },
			//选择数据后回调
			// selWhGoodsData(selRow) {
			// 	this.dialogSelVisible = false;
			// 	console.log('selRow', selRow);
			// 	if (1 == this.dialogSelType) {
			// 		this.form.goodsSku = selRow.goodsSku;
			// 	} else {
			// 		let row = this.openRowData;
			// 		// row.putWhNo = selRow.putWhNo;
			// 		// row.plPlaceNo = selRow.placeNo;
			// 		row.goodsSku = selRow.goodsSku;
			// 		// row.sendAcount = 0;
			// 		// row.mySkuDto = selRow;

			// 		this.$set(this.form.recordLists, this.openRowIndex, row);
			// 	}
			// },

			// handleSelectStatus(key, keyPath) {
			// 	// console.log(key, keyPath);
			// 	this.filterData.status = key;
			// 	this.initData();
			// },

			//分页的筛选项数据
			pageFilterData() {
				return {
					// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					userId: this.filterData.userId ? this.filterData.userId : null,
					// status: this.filterData.status ? this.filterData.status : null,
					// whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// // subjectName: this.filterData.subjectName ? this.filterData.subjectName : null,
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					// // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// // goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
					// keyword: this.filterData.keyword ? this.filterData.keyword : null
					// // declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhThirdYiCangConfPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//明细
			// getDetList(breakId) {
			// 	this.loading = true;
			// 	let HttpType = {};
			// 	HttpType = this.$http.get(this.$urlConfig.WhSkuSplitDetList + '/' + breakId, {});
			// 	HttpType.then(({
			// 		data
			// 	}) => {
			// 		console.log(data);
			// 		this.loading = false;
			// 		if (200 == data.code) {
			// 			this.form.recordLists = data.rows;
			// 		} else {
			// 			this.$alert(data.msg ? data.msg : this.$t('i18nn_d74d0bd89431d6d5'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 		}
			// 	}).catch(error => {
			// 		console.log(error);
			// 		console.log(this.$t('tips.submitError'));
			// 		this.loading = false;
			// 		this.$alert(this.$t('i18nn_d74d0bd89431d6d5'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		});
			// 	});
			// },
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			// selRow(event, row) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },
			//易仓拉取查询-仓库
			// getPullYiCangWhQuery() {
			// 	// let _this = this;
			// 	this.loading_sel = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhThirdYiCangWhQuery+"/"+this.filterData.userId+"/warehouse", {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('易仓拉取查询-仓库');
			// 			console.log(data);
			// 			
			// 			this.loading_sel = false;
			// 			this.pullYiCangWhData = data.data;

			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			// console.log('分页，请求失败');
			// 			this.loading_sel = false;
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_no = data.data['wh_no'];
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {
	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>
